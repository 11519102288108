import React from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { navigate } from 'gatsby';


const displayCtas = ( ctas, justify ) => (
  <div className="hero__ctas">
    <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
      
      {
        ctas.map(cta => (
          <div key={ cta.button_label } className="col-md">
            <Link className="button button--100" to={cta.button_url}>
              { cta.button_label }
            </Link>
          </div>
        ))
      }

    </div>
  </div>
)

export default function WardSearch({ size, video, page }) {
  const [propertyList, setPropertyList] = useState([]);
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [notification, setNotification] = useState(null);
  // = = = = = = = = 
  // Hooks

  // Initial Hook: retrieves companies Data
  const fetchPropertyList = async () => {
    await axios.get('/.netlify/functions/getAddressWithWard')
      .then( (response) => {
        const { addressWithWard } = response.data;
        setPropertyList(addressWithWard);
      })
      .catch( error => console.log(error) )
  }

  useEffect( () => fetchPropertyList(), []);


  // = = = = = = = = 
  // EventHandlers

  const handleChange = (e) => {
    e.preventDefault();
    filterData(e.target.value);
  }

  //  Returns company
  const searchData = (address, value) => {
    return (
      address?.toLowerCase()
        .replace("&", "and")
        .replace("-", "")
        .replace(/\s/g, "")
        .replace(",", "")
        .replace("'", "")
        .includes(value
          .toLowerCase()
          .replace("&", "and")
          .replace("-", "")
          .replace(/\s/g, "")
          .replace(",", "")
          .replace("'", "")
        )
    );
  }

  // Filter Data when query updates
  const filterData = (value) => {
    if(propertyList?.length < 1) return;
    if(value.length < 3) {
      setFilteredAddresses([]);
      setNotification(null);
      return;
    }
    
    const filteredAddress = propertyList?.filter(property => searchData(property.address, value));
    setFilteredAddresses(filteredAddress);

    if(filteredAddress?.length < 1) {
      setNotification('The address is not listed')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/wards');
  }


  return (
    <div className={`hero hero--search hero--${size} hero--${page ? page : ''}`} >
      {
        video
          ? <div className="hero__background-video">
              <video autoPlay loop muted playsInline>
                <source src={ video } type="video/mp4" />
              </video>
            </div>
          : ''
      }
      <div className={ `container d-flex flex-column justify-content-between`}>
        

        <div className={ `row`}>
          <div className={`ward-search__headline-wrapper col-md-10`}>
            <h1 className="ward-search__title">Find your polling station</h1>
            <h2 className="ward-search__subtitle">Please tell us where you are registered to vote, so we can allocate you to the correct ward and polling station.</h2>
          </div>
        </div>


        <div className={ `row`}>
          <div className={ `hero__form col-md-8` }>
            
              <form onSubmit={(e) => handleSubmit(e) } action="">
                <label htmlFor="">Where are you registered to vote?</label>
                <div className="input-wrapper">
                  <input onChange={handleChange} type="text" name="" id="" placeholder="Enter the name or address of your workplace or residence" />
                  <input type="submit" value="Find my ward" />
                </div>
              </form>
              {
                filteredAddresses?.length > 0 
                
                &&

                <div className="addresses-wrapper">
                  <ul className="addresses-list">
                    {
                      filteredAddresses.map(property => (
                        <li 
                          data-address={property.address} 
                          key={property.address} 
                        >
                          <Link to={`/ward/${property.ward.toLowerCase().replace("(", "").replace(")", "").replace(/\s+/g, '-')}`}>
                            <span data-address={property.address} className="item-address">{property.address}</span> 
                            <span data-address={property.address} className="item-link">Select Address ›</span>
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              }
              {
                notification && filteredAddresses?.length < 1 
                  &&
                <p className={`form__notification ${notification ? 'show' : undefined}`}>{notification}</p>
              }

          </div>
        </div>

      </div>
    </div>
  )
}