import React, { useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/Seo";
import WardSearch from '../components/WardSearch';
import video from '../../static/media/hero-video.mp4';

export default function SearchWard({ data }) {
  
  return(
    <>      
      <Seo title="Speak for the City" />
      
      <section className="ward-search">
        <WardSearch
          video = { video }
        >
          <StaticImage 
            className="hero__picture"
            src="../../static/media/home-hero.jpg" 
            alt=""
            placeholder="blurred"
            loading="eager"
          />  
        </WardSearch>
      </section>      
    </>
  )
}